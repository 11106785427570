@import './color_scheme';
@import './standard_variables';
@import '../components/noticebar/css/noticebar';

#frontPageLoginBox
{

  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 200px;
  width: 280px;
  background: $loginBox_background_color;
  border-radius: 15px 10px 10px 15px;
  display: none;
  position: absolute;
  top: 0;
  right: 90px;
  z-index: $index_always_on_top_of_top;

  .loginBoxTop
  {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $loginBox_background_color;
    padding : 0px;
    margin-left: 180px;
    margin-top: -40px;
  }

}
.frontpagePopUp
{

  &.mediaBankAppPopUp
  {
    position: relative;
    top: 0px;
    left: 356px;
    min-height: 150px;
    width: 550px;
    background: #f3f4f4;
    border-radius: 11px 11px 11px 11px;
    z-index: 1000;
    padding: 25px;
    padding-top: 5px;
    opacity: 0.9;
    line-height: 1.2em;
    text-align: left;
    display: none;
    margin-top: 150px;
  }
} // end frontpagePopUp



#header
{
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  background: none;

  div
  {
    float: none;
    &#logo
    {
      margin: 50px auto 50px auto;
      text-align: center;
      position: relative;
      img{
        width: 300px;
        @media (max-width: 650px) {
          width: 260px;
        }
      }
      .frontpagePopUp
      {
        position: relative;
        top: 0px;
        left: 356px;
        min-height: 150px;
        width: 550px;
        background: #f3f4f4;
        border-radius: 11px 11px 11px 11px;
        z-index: 1000;
        padding: 25px;
        padding-top: 5px;
        opacity: 0.9;
        line-height: 1.2em;
        text-align: left;
        display: none;
        margin-top: 150px;
      }

      img
      {
        &.mediabankTopLogo
        {
          width: 120px;
        }

        &.mediabankTopLogoText
        {
         // margin-top: -65px;
        }
      } // end img

    } // end &logo

    &#headerText
    {
      width: 400px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
    }

  } // end div

  .settingsIcon
  {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 100;

    div
    {
      float: left;
      margin-right: 10px;
    }

    .loginBox
    {
      margin-top: -4px;
    }

    .loginContainer
    {
      position: absolute;
      top: 0;
      left: -70px;
      display: block;
      min-width: 150px;
      opacity: 0;
      margin-top: 5px;
      z-index: -100;

      .loggedIn
      {
        margin-top: 10px;
      }

    } // end loginContainer



  } // end settingsIcon

  .openSettings
  {
    z-index: 100;
    padding-right: 100px;

    img
    {
      cursor: pointer;
    }
  } // end openSettings


} // end header

#frontpageSettingsContainer
{
  width: 100px;

  img
  {
    width: 50px;
  }
}


// TODO : convert to less

.reverse {
  unicode-bidi: bidi-override;
  direction: rtl;
}
#postItFrontPage {
  background: #fefabc;
  padding: 15px;
  font-size: 15px;
  color: #000;
  width: 60%;
  left: 0px;
  margin-left: 20%;
  margin-top: 240px;
  position: absolute;
  -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  box-shadow: 0px 4px 6px #333;
  -moz-box-shadow: 0px 4px 6px #333;
  -webkit-box-shadow: 0px 4px 6px #333;
  font-size: 20px;
  line-height: 1.4em;
}
#toggleLoginInContainer {
  cursor: pointer;
}
#content {
  width: 100%;
  margin: 0 auto;
}
#mainContent {
  max-width: 1280px;
  margin: 0 auto;
}

#appContainer
{
  width: 900px;
  min-height: 200px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 70px;
  .appElement
  {
    position: relative;
    float: left;

    img
    {
      width: 130px;
      padding: 20px;
    } // end img

  } // end .appElement

} // end #appContainer

.appContainer {
  width: 110px;
  position: static;
  float: left;
}

.mediaBankAppPopUp .intro, .mediaBankAppPopUp .content {
  font-size: 16px;
}
.mediaBankAppPopUp .intro h3 {
  text-transform: uppercase;
  font-size: 50px;
  font-family: alternategothic2_btregular;
}
#appContainer .appElement .appElementBubble {
  display: none;
  position: absolute;
  z-index: 0;
}
.clearAll {
  clear: both;
}

.upload.frontpagePopUp.mediaBankAppPopUp {
  margin-left: -290px;
  margin-top: 0;
  padding-bottom: 49px;
}
.review.frontpagePopUp.mediaBankAppPopUp {
  margin-left: -400px;
  margin-top: 0;
  padding-bottom: 49px;
}
.compliance.frontpagePopUp.mediaBankAppPopUp {
  margin-left: -510px;
  margin-top: 0;
  padding-bottom: 49px;
}
.library.frontpagePopUp.mediaBankAppPopUp {
  margin-left: -620px;
  margin-top: 0;
  padding-bottom: 49px;
}
.sport.frontpagePopUp.mediaBankAppPopUp {
  margin-left: -730px;
  margin-top: 0;
  padding-bottom: 49px;
}
.admin.frontpagePopUp.mediaBankAppPopUp {
  margin-left: -840px;
  margin-top: 0;
  padding-bottom: 49px;
}
#header div#logo .frontpagePopUp {
  left: -75px;
  position: absolute;
  margin-top: 110px;
}
#header div#logo .frontpagePopUp .content, .frontpagePopUp.mediaBankAppPopUp .content {
  display: none;
}
#header div#logo .frontpagePopUp .intro, .frontpagePopUp.mediaBankAppPopUp .intro {
  display: block;
}
.frontpage.readMore {
  padding-left: 6px !important;
}
.frontpagePopUp .readMore, .frontpagePopUp .video, .frontpagePopUp .closePopup, #content .closeVideoContainer {
  padding: 0px;
  display: block;
  width: 120px;
  margin-top: 15px;
  margin-right: 10px;
  padding-top: 4px !important;
}
#content .closeVideoContainer {
  width: 60px;
  float: right;
}
.frontpagePopUp .closePopup {
  float: right;
  width: 60px;
  padding-left: 4px;
}
.frontpage.blackbutton {
  float: left !important;
}
.blackbutton:hover {
  color: #eb5d33 !important;
}
.loginheader {
  color: #414042;
  font-family: alternategothic2_btregular;
  font-size: 60px;
  margin-bottom: -10px;
  margin-left: 13px !important;
  text-transform: uppercase;
  padding-left: 0px;

}
.loginInputContainer.form-group {
  margin-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;

}
.col-md-12 .loginInputContainer .form-control.loginInput {
  background: none repeat scroll 0 0 #E9E9EA;
  border-radius: 8px;
  height: auto;
  padding-top: 11px;
  color: #000;
  font-size: 16px;
}

.loginButton {
  padding-left: 4px !important;
  padding-top: 4px !important;
}
.forgotPassword {
  margin-top: 15px;
}
#frontPageLoginBox .blackbutton {
  margin-top: 10px;
}
#frontPageLoginBox.showLoginBox {
  display: block;
}
#frontPageLoginBox .checkbox {
  margin-top: 0px;
  padding-right: 0px;
  margin-right: 0px !important;
}

.videoContainerMediaBank video {
  padding: 10px;
}
#mainContent {
  position: relative !important;
}
.videoContainerMediaBank {
  padding: 10px;
  text-align: center;
  background: #dedede;
  border-radius: 10px;
  width: 80% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
  position: absolute !important;
  z-index: 1000;
  top: 140px;
  display: none;
}
#frontpageTopBar {
 // height: 28px;
  position: relative;
  padding: 10px;
}
#frontpageTopBar #needAnAccount span {
  cursor: pointer;
  margin-left: 15px;
  color: #428bca;
}
#frontpageTopBar #needAnAccount #contactInformationBoxLogin {
  display: none;
  padding: 15px;
  width: 100%;
  background: #353435;
  z-index: 1;
  box-shadow: 2px 2px 4px #2d2d2d;
}
#frontpageTopBar #frontpageContactUs {
  position: absolute;
  top: 5px;
  left: 0;
  color: #fff;
  margin-left: 10px;
  margin-top: -2px;
}
#frontpageTopBar #frontpageContactUs .text {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 300;
  color: #3d3d3d;
}
#frontpageTopBar #frontpageContactUs #contactInformationBox {
  display: none;
  margin-left: -10px;
  margin-top: 30px;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 300px;
  background: #353435;
  z-index: 1;
  box-shadow: 2px 2px 4px #2d2d2d;
}
#frontpageTopBar #frontpageContactUs #contactInformationBox div a {
  text-decoration: none;
  color: #fff;
}
#frontpageTopBar #frontpageContactUs #contactInformationBox div a:hover {
  color: #eb5d33;
}
#frontpageTopBar .frontpageTopMenuElement {
  float: right;
  color: #fff;
  position: relative;
  .logged-in-username {
    background-color: initial;
    color: black;
    position: relative;
    right: 0;
    top: 0;
    font-size: 14px;
    padding: 10px;
    font-weight: lighter;
    a {
      color: black;
    }
  }

}

#frontpageTopBar .frontpageTopMenuElement img {
  margin-top: -2px;
  margin-right: 6px;
}
#frontpageTopBar .frontpageTopMenuElement .text {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 300;
  color: #3d3d3d;
}
@media screen and (max-width: 480px) {
  #footer {
    height: auto;
    font-size: 12px;
  }
  .col-md-2 {
    width: 100%}
  .mediaBankTopLogo {
    width: 100%}
  .loginBoxTop {
    display: none;
  }
  #frontPageLoginBox {
    border-radius: 0;
    left: 0;
    margin-left: -211px;
    margin-top: 20px;
    position: absolute;
    top: 9px;
    width: 313px;
  }
}@media screen and (max-width: 991px) {
  #mainContent {
 //   width: 120px;
    margin: 0 auto;
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  #appContainer {
    margin-top: 0px !important;
  }
  #appContainer .appElement {
    margin-top: 10px !important;
  }
}
#wrap.frontpage {
  .frontpage-appswitcher {
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  section.appswitch ul {
    margin: 50px auto 20px;
    display: table;
  }
  li.review, li.sport{
    .app-text{
      display: none;
    }
  }
}

.welcome-message {
  text-align: center;
}
.welcome-cust{
  text-transform: capitalize;
}
span.welcome-name, span.welcome-cust {
  font-size: 24px;
}
.usernameError{
 text-transform: capitalize;
}
#appContainer.errorPage {
  margin-bottom: 20px;
}
.errorAppSwitch{
  text-align: center;
  overflow: hidden;
  background-color: transparent;
  border: none;
  box-shadow: none;
  p.app-text{
    display: none;
  }
  section.appswitch li a img.color-icons{
    display: block;
  }
  section.appswitch{
    text-align: center;
    ul {
      margin: 40px auto;
      text-align: center;
      display: inline-block;
      clear: both;
    }
  }

}
.pageNotFound {
  text-align: center;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 10px;
}

// Override _header.scss
#changeUserRepresentCompany {
  margin-top: inherit;
  text-align: center;
  padding-top: 2px;
  display: none;
}
#job-monitor-page {
  #loggedInUsername {
    display: none;
  }
}
#content{
  #logo img{
    max-width: 300px;
  }
}
