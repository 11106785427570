/* Paddings */
$standard_content_padding: 15px;

$padding_button_right: 6px;
$padding_button_left:6px;

/* Standard Box shadow */
$standard_box_shadow: 2px 2px 4px #2d2d2d;
$title_bar_box_shadow: 0px 1px 1px #2a2a2a;
$tiny_box_shadow: 1px 1px 2px #2d2d2d ;
/* overlays and indexes */

$standard_overlay_color: none repeat scroll 0 0 #2B2B2B;
$index_always_on_top: 100000;
$index_always_on_top_of_top:1000001;
$index_always_on_top_of_top_of_top:1000002;
$zindex_behind_overlay: 1001;




/* font sizes */

$font_size_titlebar_popup: 19px;
$font_size_small:11px;
$font_size_standard:12px;
$font_size_medium: 14px;
$font_size_large: 16px;
/* font name */

$font_gothic_regular: 'alternategothic2_btregular';
$font_helvetica_normal : 'HelveticaNeueLTStdLt';
/* borders */
$border_standard:1px solid #A2A2A2;
$border_black: 1px solid #000;


/*background */
$background_box_standard: #fefefe;
$background_box_dark: #353435;