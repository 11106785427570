
/* standard colors */

$color_black: #000;
$color_white: #fff;
$color_green_success: #dff0d8;
$color_green_success_border: #cfe0c8;
$color_red_warning:  #F2DEDE;
$color_red_warning_border: #EED3D7;
$color_blue_link:#428BCA;
$color_darkest_grey: #444445;
$color_dark_grey: #b2b2b2;
$color_grey: #dedede;
$color_grey_border: #fefefe;
$color_light_grey:#E9E9EA;
$color_ultralight_grey: #fafafa;

$color_discussion: #51B9E0;
$color_changerequest:#fac059;
$color_quicklinkcomment:#eb5d33;
$color_approval:#62d450;


$loginBox_background_color :#d4d5d6;


/* blackbutton */
$blackbutton_color:#BDBFC1;
$blackbutton_background:#414142;
/* App Colors */

$app_color_library: #eea425;
$app_color_library_lighter: #ffcc58;
$app_color_library_dark:#C37900;
$app_color_review:#51B9E0;
$app_color_review_lighter:#72CBF2;
$app_color_review_dark:#3097C0;

$app_color_sport:#6fc59e;
$app_color_sport_lighter:#8fe7bf;
$app_color_sport_dark:#4da37c;
$app_color_upload:#b8d545;
$app_color_upload_dark:#a7c434;
$app_color_upload_lighter:#c9e656;
$app_color_admin:#eb5d33;
$app_color_admin_lighter:#fc6e44;
$app_color_admin_dark:#ca4c22;
$app_color_tracking:#f39ec4;
$app_color_tracking_lighter: #ffa6ce;
$app_color_compliance:#ef4136;
$app_color_compliance_lighter:#ff4e43;

$adex-color: #b8d545;
$rna-color: #51B9E0;
$compliance-color: #ef4136;
$library-color: #eea425;
$admin-color: #eb5d33;
$dashboard-color: #ff0051;

/* Element Colors */
$background_pop_up_container: #eff0f0;

$shadow_grey: #6a6340;

$color_status_approved:#64e631;
$color_status_rejected:#eb6034;
$color_status_inprogress:#fbc159;
$color_status_new:#51b9e0;
$color_status_selected_border:#0A7AC2;


$color_posit: #FCF0AD;
$color_posit_border:#dad08b;


$error_border_color : #ca4c22;

$mb_hl_red: #ff0051;
$mb_pink: rgb(232, 0, 97);
$mb_text_color: #3d3d3d;

$mb_error_red:#DD5052;
$uefa_hive: #063676;
$uefa_hive_theme: #009bb4;

